import Vuex from 'vuex'
import Vue from 'vue'
import themeConfig from './modules/themeConfig'
import axios from "axios";

// Load Vuex
Vue.use(Vuex)

export const LS_KEY_UUID = "ls-uuid";
export const LS_KEY_AVATAR = "ls-avatar";
export const LS_KEY_CASE_DATA = "ls-case-data";
export const LS_CURRENT_STEP = "ls-current-step";
export const LS_CURRENT_SUBSTEP = "ls-current-substep";

// Create store
export default new Vuex.Store({
  state:{
    user: {
      uuid: '',
      fName: '',
      lName: '',
      email: '',
      platform: '',
      learnerType: '',
    },
    continueButtonEnabled: true,
    introduction: false,
    showCredit: false,
    showLibrary: false,
    currentStep: 1,
    currentSubStep: 1,
    sessionData: {},
    avatar: {},
    caseData: {},
    forceNextSubStepTo: null,
    lastTrackedAction: '',
  },
  mutations: {
    setContinueButtonEnabled (state, value) {
      state.continueButtonEnabled = value
    },
    setIntroduction (state, value) {
      state.avatar = {}
      localStorage.setItem(LS_KEY_AVATAR, JSON.stringify(state.avatar))
      state.introduction = value
    },
    setShowCredit (state, value) {
      state.showCredit = value
    },
    setShowLibrary (state, value) {
      state.showLibrary = value
    },
    setUser (state, value) {
      state.user = value
    },
    setCurrentStep (state, value) {
      state.currentStep = value
      if (value && value >= 1) {
        localStorage.setItem(LS_CURRENT_STEP, value);
      }
    },
    setCurrentSubStep (state, value) {
      state.currentSubStep = value
      if (value && value >= 1) {
        localStorage.setItem(LS_CURRENT_SUBSTEP, value);
      }
    },
    setSessionData (state, { key, value }) {
      state.sessionData[key] = value
    },
    setAvatar (state, values) {
      state.avatar = values
      localStorage.setItem(LS_KEY_AVATAR, JSON.stringify(state.avatar))
    },
    setAvatarValue (state, { key, value }) {
      state.avatar[key] = value
    },
    setCaseDataMt (state, values) {
      state.caseData = values
      localStorage.setItem(LS_KEY_CASE_DATA, JSON.stringify(state.caseData))
    },
    setCaseDataValueMt (state, { key, value, fullObject }) {
      state.caseData[key] = value
      if (fullObject) {
        state.caseData[key+'FullObject'] = fullObject
      }
      localStorage.setItem(LS_KEY_CASE_DATA, JSON.stringify(state.caseData))
    },
    setForceNextSubStepTo (state, value) {
      state.forceNextSubStepTo = value
    },
  },
  actions: {
    setCaseData ({commit, dispatch}, values) {
      commit('setCaseDataMt', values)
      dispatch('pushCaseData')
    },
    setCaseDataValue ({commit, dispatch}, { key, value, fullObject, trackAction = true}) {
      commit('setCaseDataValueMt', { key, value, fullObject })
      dispatch('pushCaseData')
      if (trackAction) dispatch('trackingAction', `${key} selected as ${value}`)
    },
    async pushCaseData({ state }) {
      const uuid = state.user.uuid
      var payload = {
        uuid,
        id_case: 1,
        step: state.currentStep,
        substep: state.currentSubStep,
        avatar: JSON.stringify(state.avatar),
        case_data: JSON.stringify(state.caseData)
      };
      var config = {
        method: "post",
        url: `${process.env.VUE_APP_API_ENDPOINT}/case-data/${uuid}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };

      await axios(config)
        .then(function(response) {})
        .catch(function(error) {});
    },
    async trackingAction({ state }, action) {
      if (state.lastTrackedAction === action) {
        return
      }
      state.lastTrackedAction = action
      
      const user = state.user
      const currentStep = state.currentStep
      const currentSubStep = state.currentSubStep

      if (action === "init") {
        var data = JSON.stringify({
          uuid: user.uuid,
          platform: user.platform,
          firstName: user.fName,
          lastName: user.lName,
          email: user.email,
          learnerType: user.learnerType,
        });

        var config = {
          method: "post",
          url: `${process.env.VUE_APP_API_ENDPOINT}/log_user`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config)
          .then(function(response) {})
          .catch(function(error) {});
      } else {
        var data = JSON.stringify({
          uuid: user.uuid,
          case: 1,
          step: currentStep,
          subStep: currentSubStep,
          action: action,
        });

        var config = {
          method: "post",
          url: `${process.env.VUE_APP_API_ENDPOINT}/log_action`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config)
          .then(function(response) {})
          .catch(function(error) {
            console.log(error);
          });
      }      
    },
  },
  modules: {
    themeConfig,
  },
})
