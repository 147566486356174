<template>
  <div>
    <v-progress-linear
      color="orange accent-2"
      indeterminate
      rounded
      height="6"
    />
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>
