<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title: "BYOCS - MLI",
      titleTemplate: "%s | BYOCS",
    };
  },
};
</script>
